<!-- Start Contact -->
<section class="section pt-5 mt-4" id="home">
    <div class="container-fluid shadow-md">
      <div class="row">
        <div class="col-12 p-0">
          <div class="card map border-0">
            <div class="card-body p-0">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2231.033808558513!2d29.092173813291602!3d37.73943102725935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c73f28008f7c8d%3A0x66d205fe1aca0433!2sPamukkale%20Teknokent!5e0!3m2!1str!2str!4v1709794647854!5m2!1str!2str"
                style="border:0" allowfullscreen></iframe>
                
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
    <div class="container mt-100 mt-60 ">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1 shadow-md">
          <div class="card custom-form p-4 mt-3 mb-3 ">
           
              <p id="error-msg" class="mb-0"></p>
              <div id="simple-msg"></div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Adınız <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input [(ngModel)]="message.senderName" type="text" class="form-control ps-5" placeholder="Adı-Soyadı :">
                    </div>
                  </div>
                </div>
  
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Email <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input [(ngModel)]="message.email" type="email" class="form-control ps-5" placeholder="Email :">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Şirket Adı</label>
                    <div class="form-icon position-relative">
                      <i-feather name="users" class="fea icon-sm icons"></i-feather>
                      <input [(ngModel)]="message.companyName" class="form-control ps-5" placeholder="Şirket Adı :">
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Telefon :</label>
                    <div class="form-icon position-relative">
                      <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                      <input [(ngModel)]="message.phoneNumber" type="tel" class="form-control ps-5"
                        placeholder="Telefon :">
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Konu</label>
                    <div class="form-icon position-relative">
                      <i-feather name="book" class="fea icon-sm icons"></i-feather>
                      <input [(ngModel)]="message.subject" class="form-control ps-5" placeholder="Konu :">
                    </div>
                  </div>
                </div>
                <!--end col-->
  
                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Mesajınız <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                      <textarea [(ngModel)]="message.message" rows="4" class="form-control ps-5"
                        placeholder="İletmek istediğiniz mesajı yazınız :"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="d-grid">
                    <button (click)="sendMessageToSellystic()" class="btn btn-primary">Gönder</button>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
             
          </div>
          <!--end custom-form-->
        </div>
        <!--end col-->
  
        <div class="col-lg-7 col-md-6 order-1 order-md-2">
          <div class="title-heading ms-lg-4">
            <h4 class="mb-4">İletişim Bilgileri</h4>
            <p class="text-muted">Tüm işinizi <span class="text-primary fw-bold">Sellystic</span> ile yönetin. Kolay ihracat fırsatını kaçırmayın.</p>
            <div class="d-flex contact-detail align-items-center mt-3">
              <div class="icon">
                <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
              </div>
              <div class="flex-1 content">
                <h6 class="title fw-bold mb-0">Email</h6>
                <a href="mailto:contact@example.com" class="text-primary">iletisim&#64;sellystic.com</a>
              </div>
            </div>
  
            <div class="d-flex contact-detail align-items-center mt-3">
              <div class="icon">
                <i-feather name="phone" class="fea icon-m-md text-dark me-3"></i-feather>
              </div>
              <div class="flex-1 content">
                <h6 class="title fw-bold mb-0">Telefon</h6>
                <a href="tel:+905466187928" class="text-primary">0 546 618 79 28</a>
              </div>
            </div>
  
            <div class="d-flex contact-detail align-items-center mt-3">
              
              
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- End contact -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->