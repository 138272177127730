<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" style="background: url('assets/images/shape02.png') top left;"
    id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Fiyatlandırma </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/">Sellystic</a></li>

                    <li class="breadcrumb-item active" aria-current="page">Fiyatlar</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->



<section class="section">
    <div class="container">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">Yatırımınızın Karşılığını Maksimize Edin</h4>
                        <p class="text-muted para-desc mb-0 mx-auto"><span
                                class="text-primary fw-bold">Sellystic</span> ile, işletmenizin büyümesini ve küresel pazarda öne çıkmasını 
                                sağlayacak çözümlere adil ve şeffaf fiyatlandırma ile erişin. Her bütçeye uygun, esnek fiyatlandırma planlarımızla iş hedeflerinize
                                 ulaşmanız için gereken her şeyi sunuyoruz. Başarı yolculuğunuzda yanınızda olmaktan gurur duyarız.</p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row align-items-center">
                <div class="col-12 mt-4 pt-2">
                    <div class="text-center">
                        <ul ngbNav #nav="ngbNav"
                            class="nav nav-pills rounded-pill justify-content-center d-inline-block border py-1 px-2"
                            id="pills-tab" role="tablist">
                            <li ngbNavItem class="nav-item d-inline-block">
                                <a ngbNavLink class="nav-link px-3 rounded-pill">
                                    Amazon Danışmanlığı
                                </a>
                                <ng-template ngbNavContent>
                                    <app-pricing [pricingData]="amazon"></app-pricing>
                                </ng-template>
                            </li>
                            <li ngbNavItem class="nav-item d-inline-block">
                                <a ngbNavLink class="nav-link px-3 rounded-pill">
                                    İçerik Üretimi 
                                </a>
                                <ng-template ngbNavContent>
                                    <app-pricing [pricingData]="amazoncontent"></app-pricing>
                                </ng-template>
                            </li>
                            <li ngbNavItem class="nav-item d-inline-block">
                                <a ngbNavLink class="nav-link px-3 rounded-pill">
                                    Daha Fazla
                                </a>
                                <ng-template ngbNavContent>
                                    <app-pricing [pricingData]="other"></app-pricing>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="col-12"></div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
        <!-- Price End -->       
    </div>
    <!--end container-->
</section>
<!--end section-->