<!-- Hero Start -->
<section class="bg-half-100 d-table w-100 mb-5" data-jarallax='{"speed": 0.5}'
    style="background:url('assets/images/finance/bg.jpg') top" id="home">
    <div class="bg-overlay opacity-6"></div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title-heading">
                    <div class="alert alert-white alert-pills shadow" role="alert">
                     
                    </div>
                    <h1 class="heading fw-bold mb-3">
                        <ngx-typed-js [strings]="[ 'Amazon', 'Marka', 'Yazılım', 'Reklam' ]" [shuffle]="true"
                            [typeSpeed]="200" [loop]="true">
                            Sellystic'in <span class="element text-primary typing"></span>
                        </ngx-typed-js>
                        Çözümlerini Deneyimleyin..
                    </h1>
                    <p class="para-desc text-white-50">Şimdi bize başvurabilir ve işlemleriniz hakkında geniş bilgi alabilirsiniz.</p>
                    <div class="mt-4 pt-2">
                        
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<section class="section" id="courses">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Tüm Çözümlerimizi Keşfedin</h4>
                    <p class="text-muted para-desc mx-auto mb-0">İşinizi <span
                            class="text-primary fw-bold">Sellystic</span> ile kolaylaştırın.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            @for (item of solutions; track $index) {
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
                    <div class="position-relative d-block overflow-hidden">
                        <img src="{{item.image}}" class="img-fluid rounded-top mx-auto" alt="{{item.title}}">
                        <div class="overlay-work bg-dark"></div>
                    </div>

                    <div class="card-body">
                        <h5><a class="title text-dark">{{item.title}}</a></h5>
                        <div class="card-body py-3 px-0 content">
                            <small class="designation text-muted">{{item.description}}</small>
                        </div>
                    </div>
                </div>
            </div>
            }
            <!--end col-->




        </div>
        <!--end row-->
    </div>
    <!--end container-->


</section>
