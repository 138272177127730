import { Injectable } from '@angular/core';
import { ContactModel } from '../models/contactModel';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MailServiceService {

  constructor(private httpClient:HttpClient) { }

  sendContactUs(item:ContactModel):Observable<any>{
    return this.httpClient.post(environment.getApiUrl + "MailOperations/contact-us",item,{responseType:'text'});
  }
}
