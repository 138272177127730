import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Swal from 'sweetalert2';

if (environment.production) {
  enableProdMode();
}
// SweetAlert2 için Global Ayarlar
Swal.mixin({
  customClass: {
    confirmButton: 'swal2-confirm-button', // Bu sınıflar CSS ile özelleştirilecek
    cancelButton: 'swal2-cancel-button'
  },
  buttonsStyling: false, // SweetAlert2'nin varsayılan buton stillerini devre dışı bırak
  confirmButtonColor: '#0A393E', // Onay butonu için renk
  cancelButtonColor: '#0A393E', // İptal butonu için renk
  iconColor: '#0A393E', // İkon renkleri için
  showCloseButton: true, // kapatma için çarpı butonu
  allowOutsideClick: false // dışarı tıklayınca kapanmasın
});
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
