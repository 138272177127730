import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AmazonConsultingCategoryDetailDto } from 'src/app/models/amazonConsultingCategoryDetailDto';
import { AmazonConsultingCategoryDto } from 'src/app/models/amazonConsultingCategoryDto';
import { CategoryDetailHtmlPipe } from 'src/app/pipes/category-detail-html.pipe';
import { MainPageService } from 'src/app/services/main-page.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-amazon',
  standalone: false,
  templateUrl: './amazon.component.html',
  styleUrl: './amazon.component.css',
  providers:[CategoryDetailHtmlPipe]
})
export class AmazonComponent  implements OnInit,AfterViewInit {

  ngAfterViewInit(): void {
    this.gotoId();
  }
  gotoId() {
    document.getElementById('content-block')?.scrollIntoView({ behavior: 'smooth' });
  }

  // Set Topbar Option
  navClass = 'nav-dark';
  Menuoption = 'center';
  footerClass: true;
  footerVariant = 'bg-light';
  Settingicon = true
  customOptions: OwlOptions = {
    // loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    // autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: true,
  };

  seoAgency = [];

  /**
   * Services Data
   */
  servicesData = [
    {
      icon: "uil uil-chart-line h1 text-primary",
      title: "Grow your traffic",
      description: "Nisi aenean vulputate eleifend tellus vitae eleifend enim a eleifend Aliquamaenean elementum semper."
    },
    {
      icon: "uil uil-adjust-circle h1 text-primary",
      title: "Get quality leads",
      description: "Allegedly, a Latin scholar established the origin of the established text by compiling unusual word."
    },
    {
      icon: "uil uil-award h1 text-primary",
      title: "Drive more sell",
      description: "It seems that only fragments of the original text remain in only fragments the Lorem Ipsum texts used today."
    }
  ];

  /**
   * Client Testimonial Data
   */
  testimonialData = [
    {
      profile: "assets/images/client/01.jpg",
      name: "Thomas Israel",
      designation: "C.E.O",
      message: `" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "`
    },
    {
      profile: "assets/images/client/02.jpg",
      name: "Barbara McIntosh",
      designation: "M.D",
      message: `" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "`
    },
    {
      profile: "assets/images/client/03.jpg",
      name: "Carl Oliver",
      designation: "P.A",
      message: `" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "`
    },
    {
      profile: "assets/images/client/04.jpg",
      name: "Christa Smith",
      designation: "Manager",
      message: `" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "`
    },
    {
      profile: "assets/images/client/05.jpg",
      name: "Dean Tolle",
      designation: "Developer",
      message: `" There is now an abundance of readable dummy texts. These are usually used when a text is required. "`
    },
    {
      profile: "assets/images/client/06.jpg",
      name: "Jill Webb",
      designation: "Designer",
      message: `" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "`
    }
  ];

  isActive: string = "month";

  /**
   * Tab change value
   * @param value
   */
  changeTab(value: string) {
    this.isActive = value;
  }

  navigate(id:number){
    this.mainPageService.setSelectedId(id);
    this.router.navigate(['/amazon-detail'])
  }

 
  constructor(private modalService: NgbModal, private mainPageService:MainPageService, private pipe:CategoryDetailHtmlPipe, private router:Router) 
  {
    this.uri =environment.getApiBase;
    this.getAmazonDetail(1);
    this.getAmazonConsultingCategories(); 
    
  }
  uri:string;
  ngOnInit(): void {
    
  }

  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 2,
    decimalPlaces: 0,
  };
  categoryDetail:AmazonConsultingCategoryDetailDto = new AmazonConsultingCategoryDetailDto();
  categoryList:AmazonConsultingCategoryDto[]=[];
  getAmazonConsultingCategories(){
    this.mainPageService.getAmazonConsultingCategories().then(data =>{
      this.categoryList = data;
      this.categoryList.forEach(x=> {
        this.seoAgency.push({images:this.uri+x.imageURL, alts:x.imageAlt, ids:x.categoryID});
      });
      
    }).catch(error => {
      
      
    });
    
    
  }
  dynamicHtmlContent: SafeHtml;
  getAmazonDetail(item:number){
    this.mainPageService.getCategoryDetail(item).then(data=>{
      this.categoryDetail = data;
      let x = '<div class="section-title mb-4 pb-2"><h4 class="title mb-4">'+this.categoryDetail.contentTitle+'</h4>';
      this.categoryDetail.contentPart = x + this.categoryDetail.contentPart;

      this.dynamicHtmlContent = this.pipe.transform(this.categoryDetail.contentPart);
      this.gotoId();
    })
  }
  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

  goContactUs(){
    
      
      this.router.navigate(['/contact-us'])
    
  }

  goAmazon(){
    this.router.navigate(['/amazon'])
  }

}
