import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-our-solutions',
  standalone: false,
  templateUrl: './our-solutions.component.html',
  styleUrl: './our-solutions.component.css'
})
export class OurSolutionsComponent {


  constructor(private modalService: NgbModal){}


   // Set Topbar Option
   Menuoption = 'center';
   Settingicon = true
   showNavigationArrows = false;
   showNavigationIndicators = false;
  /***
     * nav bg class add
     */
  navClass = 'navbar-white-bg';


 
  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 2,
    decimalPlaces: 0,
  };

  /**
   * Member Data
   */
  memberData = [
    {
      profile: "assets/images/client/01.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ronny Jofra",
      designation: "C.E.O"
    },
    {
      profile: "assets/images/client/04.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Micheal Carlo",
      designation: "Director"
    },
    {
      profile: "assets/images/client/02.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Aliana Rosy",
      designation: "Manager"
    },
    {
      profile: "assets/images/client/03.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Sofia Razaq",
      designation: "Developer"
    }
  ];

  solutions = [
    {
      image: "assets/images/our-solution/1.jpg",
      title: "Pazar Analizi",
      description: "Sektör trendlerini, hedef kitle davranışlarını ve pazarın genel yapısını analiz ediyoruz."
    },
    {
      image: "assets/images/our-solution/2.jpg",
      title: "Hedef Kitle Araştırması",
      description: "Markanız için en değerli müşteri segmentlerini belirliyoruz."
    },
    {
      image: "assets/images/our-solution/3.jpg",
      title: "Rekabet Analizi",
      description: "Rakiplerinizi yakından inceliyor ve stratejilerinizi buna göre şekillendiriyoruz."
    },
    {
      image: "assets/images/our-solution/4.jpg",
      title: "Marka Kimliği Geliştirme",
      description: "Logo, renk paleti ve marka sesi gibi unsurlarla güçlü bir marka kimliği oluşturuyoruz."
    },
    {
      image: "assets/images/our-solution/5.jpg",
      title: "Dijital Pazarlama Stratejileri",
      description: "SEO, sosyal medya yönetimi ve içerik pazarlama gibi dijital kanallarda markanızı öne çıkarıyoruz."
    },
    {
      image: "assets/images/our-solution/6.jpg",
      title: "Web Sitesi Geliştirme",
      description: "Kurumsal, kullanıcı dostu, SEO uyumlu ve mobil uyumlu web siteleri tasarlıyoruz."
    },
    {
      image: "assets/images/our-solution/7.jpg",
      title: "Yazılım Entegrasyonları",
      description: "İş süreçlerinizi otomatize eden ve kolaylaştıran yazılım çözümleri sağlıyoruz."
    },
    {
      image: "assets/images/our-solution/8.jpg",
      title: "Sosyal Medya Yönetimi",
      description: "Markanızın sosyal medya varlığını yönetiyor ve etkileşimi artırıyoruz."
    },
    {
      image: "assets/images/our-solution/9.jpg",
      title: "İçerik Üretimi",
      description: "SEO dostu ve etkileyici içeriklerle markanızın hikayesini anlatıyoruz."
    },
    {
      image: "assets/images/our-solution/10.jpg",
      title: "Lojistik ve Gümrük Danışmanlığı",
      description: "Uluslararası gönderimleriniz için lojistik ve gümrük işlemlerini kolaylaştırıyoruz."
    },
    {
      image: "assets/images/our-solution/11.jpg",
      title: "Finansal Danışmanlık",
      description: "E-ihracat işlemleriniz için finansal ödeme araçları kurgusu ve yönetim danışmanlığı veriyoruz."
    },
    {
      image: "assets/images/our-solution/12.jpg",
      title: "Eğitim ve Gelişim Programları",
      description: "Ekip üyelerinizin becerilerini geliştirmek için eğitim programları düzenliyoruz."
    },
    {
      image: "assets/images/our-solution/13.jpg",
      title: "Amazon Mağaza Kurulumu",
      description: "Amazon üzerinde markanızın mağazasını kurma ve optimizasyon işlemleri."
    },
    {
      image: "assets/images/our-solution/14.jpg",
      title: "Kaufland Mağaza Kurulumu",
      description: "Kaufland üzerinde markanızın mağazasını kurma ve optimizasyon işlemleri."
    },
    {
      image: "assets/images/our-solution/15.jpg",
      title: "Otto Mağaza Kurulumu",
      description: "Otto üzerinde markanızın mağazasını kurma ve optimizasyon işlemleri."
    },
    {
      image: "assets/images/our-solution/16.jpg",
      title: "Wayfair Mağaza Kurulumu",
      description: "Wayfair üzerinde markanızın mağazasını kurma ve optimizasyon işlemleri."
    },
    {
      image: "assets/images/our-solution/17.jpg",
      title: "Amazon Satış Stratejileri",
      description: "Ürün listeleme, fiyatlandırma stratejileri ve promosyonlar dahil olmak üzere Amazon'da satış performansınızı artıracak stratejiler geliştirme."
    },
    {
      image: "assets/images/our-solution/18.jpg",
      title: "Amazon Reklam Yönetimi",
      description: "Amazon Sponsored Products ve Amazon Sponsored Brands gibi reklam çözümleri kullanarak ürünlerinizin görünürlüğünü artırma."
    },
    {
      image: "assets/images/our-solution/19.jpg",
      title: "Amazon SEO",
      description: "Ürün sayfalarınızın Amazon arama sonuçlarında daha yüksek sıralarda yer almasını sağlayacak anahtar kelime optimizasyonu ve içerik geliştirme."
    },
    {
      image: "assets/images/our-solution/20.jpg",
      title: "Amazon Yorum Yönetimi",
      description: "Müşteri yorumlarını yönetme ve olumlu geri bildirimlerin artırılmasına yönelik stratejiler."
    },
    {
      image: "assets/images/our-solution/21.jpg",
      title: "Amazon Stok ve Lojistik Yönetimi",
      description: "Amazon FBA (Fulfillment by Amazon) dahil olmak üzere stok yönetimi ve lojistik süreçlerinin optimizasyonu."
    },
    {
      image: "assets/images/our-solution/22.jpg",
      title: "Avrupa Online Pazar Yerleri Danışmanlığı",
      description: "Kaufland, Zalando, Otto gibi Avrupa'nın önde gelen online pazar yerlerinde markanızın kurulumu, yönetimi ve büyütülmesi için danışmanlık hizmetleri."
    },
    {
      image: "assets/images/our-solution/23.jpg",
      title: "Çoklu Pazar Yeri Entegrasyonu",
      description: "Amazon, eBay, Kaufland gibi birden fazla online pazar yerinde etkin bir şekilde satış yapmanızı sağlayacak entegrasyon çözümleri."
    },
    {
      image: "assets/images/our-solution/24.jpg",
      title: "Uluslararası Pazar Araştırması",
      description: "Amazon ve diğer global pazar yerlerinde başarılı olmak için gerekli olan uluslararası pazar araştırması ve analiz."
    },
    {
      image: "assets/images/our-solution/25.jpg",
      title: "E-ihracat Danışmanlığı",
      description: "Amazon ve Avrupa pazar yerleri başta olmak üzere, e-ihracat yapmak isteyen işletmelere yönelik kapsamlı danışmanlık ve destek hizmetleri."
    },
    {
      image: "assets/images/our-solution/26.jpg",
      title: "Marka Danışmanlığı",
      description: "Markanızın değerini artıracak stratejik danışmanlık hizmetleri sunarak, markanızın pazardaki konumunu güçlendirme."
    },
    {
      image: "assets/images/our-solution/27.jpg",
      title: "Lojistik Süreçlerin Kolaylaştırılması",
      description: "Uluslararası nakliye, gümrük işlemleri ve yerel lojistik operasyonlarınızın verimliliğini artıracak çözümler."
    },
    {
      image: "assets/images/our-solution/28.jpg",
      title: "Ürün Görselleri ve Video Çekimi",
      description: "Yüksek kaliteli ürün fotoğrafları ve tanıtım videoları ile online satış potansiyelinizi maksimize edin."
    },
    {
      image: "assets/images/our-solution/29.jpg",
      title: "A+ Content İçerik Oluşturma",
      description: "Amazon online pazar yeri için dikkat çekici, satışları artıran A+ içerikler hazırlama."
    },
    {
      image: "assets/images/our-solution/30.jpg",
      title: "Ürün Yüklemesi ve Listeleme",
      description: "Amazon, eBay, Kaufland gibi platformlara ürünlerinizin etkili bir şekilde yüklenmesi ve listelenmesi."
    },
    {
      image: "assets/images/our-solution/31.jpg",
      title: "Kişiselleştirilmiş Yazılım Çözümleri",
      description: "İşletmenizin ihtiyaçlarına özel olarak tasarlanmış CRM, ERP ve diğer iş yönetimi yazılımları."
    },
    {
      image: "assets/images/our-solution/32.jpg",
      title: "E-ihracat için SEO ve Pazarlama",
      description: "Global pazar yerlerinde ürünlerinizin daha görünür olması için SEO ve dijital pazarlama stratejileri."
    },
    {
      image: "assets/images/our-solution/33.jpg",
      title: "Sosyal Medya Optimizasyonu",
      description: "Markanızın sosyal medya platformlarında daha etkili bir şekilde tanıtımını sağlama."
    },
    {
      image: "assets/images/our-solution/34.jpg",
      title: "Veri Analizi ve Raporlama",
      description: "İşletmenizin performansını izlemek ve iyileştirmek için gelişmiş veri analizi ve raporlama hizmetleri."
    },
    {
      image: "assets/images/our-solution/35.jpg",
      title: "Eğitim ve Danışmanlık Hizmetleri",
      description: "Ekip üyelerinizin e-ihracat, dijital pazarlama ve teknoloji konularında bilgi ve becerilerini artıracak eğitim programları."
    }
    
  ]
}
