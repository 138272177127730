export class ContactModel{
    senderName:string="";
    companyName:string="";
    email:string="";
    subject:string="";
    message:string="";
    phoneNumber:string="";
    reset(){
        this.senderName ="";
        this.companyName ="";
        this.email="";
        this.subject = "";
        this.message = "";
        this.phoneNumber="";
    }
}