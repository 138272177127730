import { Component, Input, OnInit } from '@angular/core';
import { AmazonConsultingDto } from 'src/app/models/amazonConsultingDto';
import { AmazonConstultingHtmlPipe } from 'src/app/pipes/amazon-constulting-html.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consulting-detail',
  standalone: false,
  templateUrl: './consulting-detail.component.html',
  styleUrl: './consulting-detail.component.css',
  providers:[AmazonConstultingHtmlPipe]
})
export class ConsultingDetailComponent implements OnInit {
  @Input() items: AmazonConsultingDto;
  uri:string="";
  constructor(private pipe:AmazonConstultingHtmlPipe){
    this.uri = environment.getApiBase;
  }
  ngOnInit(): void {
    console.log(this.items);
  }
}
