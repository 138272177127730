import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from "../../shared/shared.module";
import { Router } from '@angular/router';

@Component({
    selector: 'app-main',
    standalone: false,
    templateUrl: './main.component.html',
    styleUrl: './main.component.css',
    
})
export class MainComponent implements OnInit {

  /**
   * nav Light class Add
   */
  navClass = 'nav-light';
    // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true

  constructor(private modalService: NgbModal,private router:Router) { }
  

  /**
   * Client Testimonial Data
   */
  testimonialData = [
    {
      profile: "assets/images/client/01.jpg",
      name: "Thomas Israel",
      designation: "C.E.O",
      message: `" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "`
    },
    {
      profile: "assets/images/client/02.jpg",
      name: "Barbara McIntosh",
      designation: "M.D",
      message: `" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "`
    },
    {
      profile: "assets/images/client/03.jpg",
      name: "Carl Oliver",
      designation: "P.A",
      message: `" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "`
    },
    {
      profile: "assets/images/client/04.jpg",
      name: "Christa Smith",
      designation: "Manager",
      message: `" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "`
    },
    {
      profile: "assets/images/client/05.jpg",
      name: "Dean Tolle",
      designation: "Developer",
      message: `" There is now an abundance of readable dummy texts. These are usually used when a text is required. "`
    },
    {
      profile: "assets/images/client/06.jpg",
      name: "Jill Webb",
      designation: "Designer",
      message: `" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "`
    }
  ];

  /**
   * Services Data
   */
  servicesData = [
    // {
    //   icon: "uil uil-chart-line h1 text-primary",
    //   title: "Analiz ve Rapor",
    //   description: "E-ihracat işlemlerinizin detaylı analizi ve raporlanması, stratejik kararlar almanızı sağlar."
    // },
    // {
    //   icon: "uil uil-channel h1 text-primary",
    //   title: "Entegre Sistem",
    //   description: "Üretimden satışa, tüm süreçlerin entegre yönetimi ile verimliliği artırır."
    // },
    // {
    //   icon: "uil uil-briefcase h1 text-primary",
    //   title: "Danışmanlık Hizmetleri",
    //   description: "E-ihracatta karşılaşılan zorlukların üstesinden gelmenize yardımcı olur."
    // },
    // {
    //   icon: "uil uil-globe h1 text-primary",
    //   title: "Lojistik ve Gümrük",
    //   description: "Ürünlerinizin dünya çapında sorunsuz sevkiyatı için lojistik ve gümrük işlemleri."
    // },
    // {
    //   icon: "uil uil-presentation-lines-alt h1 text-primary",
    //   title: "Eğitim ve Destek",
    //   description: "Sürecin etkin yönetimi için gerekli bilgi ve desteği sağlar."
    // },
    // {
    //   icon: "uil uil-brain h1 text-primary",
    //   title: "Teknoloji ve İnovasyon",
    //   description: "İş süreçlerinizi modern teknoloji ile destekleyerek rekabet avantajı sağlar."
    // }
    {
      icon: "uil uil-chart-line h1 text-primary",
      title: "Detaylı Analiz ve Raporlama",
      description: "E-ihracat işlemlerinizi detaylıca analiz edip, veriye dayalı stratejik kararlar almanıza olanak tanır."
    },
    {
      icon: "uil uil-channel h1 text-primary",
      title: "Entegre Sistem Çözümleri",
      description: "Üretimden satışa, lojistikten müşteri ilişkilerine kadar iş süreçlerinizin sorunsuz entegrasyonu."
    },
    {
      icon: "uil uil-briefcase h1 text-primary",
      title: "Kişiye Özel Danışmanlık",
      description: "E-ihracat sürecinizde karşılaşacağınız zorlukların üstesinden gelmeniz için birebir destek."
    },
    {
      icon: "uil uil-globe h1 text-primary",
      title: "Küresel Lojistik ve Gümrük Danışmanlığı",
      description: "Ürünlerinizin global pazarlara sorunsuz bir şekilde ulaşımı için kapsamlı lojistik ve gümrük danışmanlığı."
    },
    {
      icon: "uil uil-presentation-lines-alt h1 text-primary",
      title: "Eğitim ve Sürekli Destek",
      description: "E-ihracat süreciniz boyunca ihtiyacınız olacak tüm bilgi ve desteği sağlamak."
    },
    {
      icon: "uil uil-brain h1 text-primary",
      title: "Yenilikçi Teknoloji Çözümleri",
      description: "Modern teknolojilerle donatılmış çözümlerimizle iş süreçlerinize değer katın ve rekabette öne geçin."
    }
  ];

  /**
   * Pricing Data
   */
  pricingData = [
    {
      title: "Free",
      price: 0,
      list: ["Full Access", "Source Files"],
      btn: "Buy Now"
    },
    {
      warning: "Best",
      title: "Starter",
      price: 39,
      list: ["Full Access", "Source Files", "Free Appointments"],
      btn: "Get Started"
    },
    {
      title: "Professional",
      price: 59,
      list: ["Full Access", "Source Files", "1 Domain Free", "Enhanced Security"],
      btn: "Try It Now"
    },
    {
      title: "Ultimate",
      price: 79,
      list: ["Full Access", "Source Files", "1 Domain Free", "Enhanced Security", "Free Installment"],
      btn: "Started Now"
    }
  ];

  ngOnInit(): void {
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

  goContactUs(){
    
      
    this.router.navigate(['/contact-us'])
  
}

goAmazon(){
  this.router.navigate(['/amazon'])
}

  goSolution(){
    this.router.navigate(['/our-solutions'])
  }

  
}
