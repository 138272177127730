import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterPageComponent } from './core/components/master-page/master-page.component';

import { SwitcherComponent } from './shared/switcher/switcher.component';

import { PageFooterComponent } from './core/components/page-footer/page-footer.component';

import { MainComponent } from './components/main/main.component';
import { ContactComponent } from './components/contact/contact.component';
import { AmazonComponent } from './components/amazon/amazon.component';
import { AmazonDetailComponent } from './components/amazon/amazon-detail/amazon-detail.component';
import { OurSolutionsComponent } from './components/our-solutions/our-solutions.component';

import { AmazonPricingComponent } from './components/amazon-pricing/amazon-pricing.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: MainComponent },
      { path:'contact-us', component: ContactComponent},
      { path:'amazon', component: AmazonComponent},
      { path:'amazon-detail', component: AmazonDetailComponent},
      { path:'our-solutions', component: OurSolutionsComponent},
      { path: '#', component: SwitcherComponent },
      { path: 'page-footer', component:  PageFooterComponent},
      { path: 'pricing', component:  AmazonPricingComponent},
    ]
  },

  //{ path: 'auth-login', component: AuthLoginComponent },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top",
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
