import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'categoryDetailHtml',
  standalone: false
})


export class CategoryDetailHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: string): SafeHtml {

    const updatedValue = value.replace(/convert\(p\)/g, '<p class="text-muted para-desc mb-0">')
    .replace(/convert\(pc\)/g, '</p>').replace(/convert\(s\)/g, '<span class="text-primary fw-bold">').replace(/convert\(sc\)/g, '</span>')
    .replace(/convert\(dc\)/g, '</div>').replace(/convert\(ul\)/g, '<ul class="list-unstyled text-muted">').replace(/convert\(li\)/g, '<li class="mb-0"><span class="text-primary h4 me-2"><i class="uil uil-check-circle align-middle"></i></span>')
    .replace(/convert\(lic\)/g, '</li>').replace(/convert\(ulc\)/g, '</ul>');
    return this.sanitizer.bypassSecurityTrustHtml(updatedValue);
  }

}
