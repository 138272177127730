import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SocialMediaLinkDto } from '../models/socialMediaLinkDto';
import { AmazonConsultingCategoryDto } from '../models/amazonConsultingCategoryDto';
import { AmazonConsultingCategoryDetailDto } from '../models/amazonConsultingCategoryDetailDto';
import { AmazonConsultingDto } from '../models/amazonConsultingDto';

@Injectable({
  providedIn: 'root'
})
export class MainPageService {

  constructor(private httpClient:HttpClient) { }

  private selectedIdSource = new BehaviorSubject<number | null>(null);
  selectedId = this.selectedIdSource.asObservable();

  setSelectedId(id: number) {
    this.selectedIdSource.next(id);
  }

  addSubscribe(item:string):Observable<any>{
    const sub:SubscribeUser = new SubscribeUser();
    
    sub.email = item;console.log(sub);
    return this.httpClient.post(environment.getApiUrl + "MainOperations/subscribe",sub,{responseType:'text'});
  }

  getSocialMediaLink():Promise<SocialMediaLinkDto[]>{
    return this.httpClient.get<SocialMediaLinkDto[]>(environment.getApiUrl + "MainPage/get-social-link-list").toPromise().then(response => response)
    .catch(error => Promise.reject(error));
  }
  getAmazonConsultingCategories():Promise<AmazonConsultingCategoryDto[]>{
    return this.httpClient.get<AmazonConsultingCategoryDto[]>(environment.getApiUrl + "MainPage/get-amazon-consulting-categories").toPromise().then(response => response)
    .catch(error => Promise.reject(error));
  }
  getCategoryDetail(id: number): Promise<AmazonConsultingCategoryDetailDto> {
    return this.httpClient.get<AmazonConsultingCategoryDetailDto>(environment.getApiUrl + "MainPage/" + id + "/get-amazon-consulting-category-detail").toPromise().then(response => response)
      .catch(error => Promise.reject(error));
  }

  getAmazonConsulting(id:number): Promise<AmazonConsultingDto[]> {
    return this.httpClient.get<AmazonConsultingDto[]>(environment.getApiUrl + "MainPage/"+id+"/get-amazon-consulting-list").toPromise().then(response => response)
      .catch(error => Promise.reject(error));
  }
}

export class SubscribeUser{
  email:string="";
}
