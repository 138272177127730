<!-- FEATURES START -->
<section class="bg-half-170 bg-light d-table w-100"
style="background: url('assets/images/real/build.png') bottom no-repeat; padding-top:60px" id="home">
    <div class="container-fluid">
        <div class="py-5" style="background: url('assets/images/svg-map.svg');">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <span class="clip-text clip-text-image text-uppercase fw-bold"
                            style="background-image: url('assets/images/logistics/01.jpg');">Sellystic</span>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </div>
    </div>

</section><!--end section-->
<!-- End -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-11 col-md-5">
                <div class="features-absolute">
                    <div id="grid" class="row">
                        <ngx-masonry style="padding-left: 50px;">
                            @for(item of seoAgency;track $index){
                            <div ngxMasonryItem class="spacing picture-item " (click)="getAmazonDetail(item.ids)">
                                <img src="{{item.images}}" class="img-fluid shadow-md seo-hero-widget rounded-md"
                                    alt="{{item.alts}}">
                            </div>
                            }
                            <!--end col-->
                        </ngx-masonry>
                    </div>
                    <!--end row-->
                </div>
                <!--end div-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

</section>
<!--end section-->

<section class="section" style="padding:10px 0px" id="content-block"> 
    <!-- About Start -->
    <div class="container">
        <div class="row align-items-center">  
            <div class="col-lg-6 col-md-6">
                <div class="row align-items-center">
                  <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                    <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                      <div class="card-body p-0" >
                        <img src="{{uri + this.categoryDetail.image1Url}}" class="img-fluid" alt="{{categoryDetail.contentTitle}}">
                        <div class="overlay-work bg-dark"></div>
                        <div class="content">
                         
                          <small class="text-light">{{categoryDetail.contentTitle}}</small>
                        </div>
                      </div>
                    </div>
                  </div>              <!--end col-->
        
                  <div class="col-lg-6 col-6">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                          <div class="card-body p-0">
                            <img src="{{uri + this.categoryDetail.image2Url}}" class="img-fluid" alt="{{categoryDetail.contentTitle}}">
                        <div class="overlay-work bg-dark"></div>
                        <div class="content">
                         
                          <small class="text-light">{{categoryDetail.contentTitle}}</small>
                        </div>
                          </div>
                        </div>
                      </div>                      <!--end col-->
        
                      <div class="col-lg-12 col-md-12 mt-4 pt-2">
                        <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                          <div class="card-body p-0">
                            <img src="{{uri + this.categoryDetail.image3Url}}" class="img-fluid" alt="{{categoryDetail.contentTitle}}">
                        <div class="overlay-work bg-dark"></div>
                        <div class="content">
                         
                          <small class="text-light">{{categoryDetail.contentTitle}}</small>
                        </div>
                          </div>
                        </div>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </div>         
            <!--end col-->
            <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
                <div class="ms-lg-4" [innerHTML]="categoryDetail.contentPart | categoryDetailHtml"> 

                  
                </div>
                <button (click)="navigate(categoryDetail.amazonConsultingCategoryID)" class="btn btn-outline-primary mt-3">Detaylı İncele</button>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>