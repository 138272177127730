import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactModel } from 'src/app/models/contactModel';
import { MailServiceService } from 'src/app/services/mail-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  standalone: false,  
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.css'
})
export class ContactComponent implements OnInit {

  // Set Topbar Option
Menuoption = 'center';
Settingicon = true
constructor(private modalService: NgbModal,private mailService:MailServiceService) { }

ngOnInit(): void {
}

mapView(content) {
this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true })
}

message:ContactModel=new ContactModel();

sendMessageToSellystic(){
  
  if(this.message.email == "" || this.message.senderName == "" || this.message.message == "" ){
    Swal.fire({
      title: 'Eksik Var!',
      text: 'Lütfen * ile belirtilen alanları doldurunuz.',
      icon: 'info',
      confirmButtonText: 'Tamam',
      showCloseButton: true, // kapatma için çarpı butonu
      allowOutsideClick: false // dışarı tıklayınca kapanmasın
    });
    return;
  }
  this.mailService.sendContactUs(this.message).subscribe(data => {
    Swal.fire({
      title: 'Gönderildi.',
      text: 'Mesajınız tarafımıza başarı ile gönderildi.',
      icon: 'success',
      confirmButtonText: 'Tamam',
      showCloseButton: true, // kapatma için çarpı butonu
      allowOutsideClick: false, // dışarı tıklayınca kapanmasın
      
    });
    this.message.reset();
  });
}
}
