import { Component } from '@angular/core';

@Component({
  selector: 'app-amazon-pricing',
  standalone: false,  
  templateUrl: './amazon-pricing.component.html',
  styleUrl: './amazon-pricing.component.css'
})
export class AmazonPricingComponent {
// Set Topbar Option
navClass = 'nav-dark';
Menuoption = 'center';
footerClass: true;
 footerVariant = 'bg-light';
Settingicon = true;
num: number = 0;







amazon = [

  {
    warning: "Başlangıç",
    title: "Temel",
    
    list: ["Türk Şirketleri İçin Avrupa Vergi Kaydı", "Çoklu Pazaryeri Hesaplarınınn Açılışı", "Hedef Ülke Ödeme Alt Yapısı Kurgusu","1 Ürünü Varyantları İle Listelenmesi"
  , "Belirlenen Bir Kategori İçin Detaylı Pazar Analizi"],
    btn: "Get Started",
    single:"Fiyat Alınız"
  },
  {
    warning: "Tam",
    title: "Profesyonel",
    
    list: ["Profesyonel Amazon Hesabı Kurulumu", "Marka Tescili ve Korunması Kurguları", "Almanya Şirket Kurulumu ve Yasal İşlemleri", "Ödeme Alt Yapısı Kurgusu",
    "1 Ürünü Varyantları İle Listelenmesi", "Belirlenen Bir Kategori İçin Detaylı Pazar Analizi"],
    single:"Fiyat Alınız"
  },
  {
    title: "Hesap Yönetimi",
    
    list: ["Amazon Ürün Satış Takibi", "Reklam Kurguları", "Müşteri Geri Bildirim Yönetimi", "Stok Takibi", "Amazon Mağaza Yönetimi","Amazon Hesap Sağlığı Takibi","Müşteri Geri Dönüşü Takibi", "Satışa Yönelik Kurgular"],
    btn: "Started Now",
    single:"Fiyat Alınız"
  }
];

amazoncontent = [

  {
    
    title: "A+ Content",
    list: ["Profesyonel A+ İçerikler", "Akılda Kalıcı Ürün Hikayesi", "Ürüne Özgü Tasarım","Tanınırlığı Artıran Tasarımlar" ],
    btn: "Get Started",
    single:"Fiyat Alınız"
  },
  {
    warning: "İçerik",
    title: "Marka Hikayesi",   
    list: ["Markanızın Özgün Hikaye Kurgusu", "Karakter ve Kimlik Oluşturma", "Detaylı Markanın Vizyon ve Misyonu"],
    single:"Fiyat Alınız"
  },
  {
    
    title: "Amazona Ürün Yükleme",    
    list: ["Amazon Kelime Ambarıdan Seçilmiş Ana Başlık Kurguları","Hedef Odaklı Ürün İçeriği", "Hedef Lokal SEO Uyumlu Ürün Açıklamaları", "Çarpıcı Ürün Başlıkları", "Bölgesel Kültüre Bağlı Anahtar Kelimeler", "Profesyonel Araçlarla Sonuçlandırılmış Ürün Girişi"],    
    single:"Fiyat Alınız"
  }
];
other = [

  {    
    title: "A-Z'ye Amazon Eğitimi",
    
    list: ["Amazon Mağaza Yönetimi", "Reklam Kurguları", "Ürün İçerik Kurguları","Analiz Araçlarının Etkin Kullanımı", "Amazon Prime Hesabı Kullanımı", "Sipariş Yönetimi", "Amazon Lojistik Yönetimi" ],
    
    single:"Fiyat Alınız"
  },
  {
    
    title: "Diğer",
   
    list: ["Şirkete Özgü Web Uygulamaları", "Kurumsal Altyapı ve Entegrasyon", "Etkin Yazılım Çözümleri","Ayrı ayrı ihtiyacınıza yönelik işlerde ayrı ayrı fiyat isteyiniz."],
    single:"Fiyat Alınız"
  },
  {
    title: "Görsel Materyal",    
    list: ["Ürünlerin Fotoğraf Çekimi", "Farklı Açı ve Kurgular", "Profesyonel Video/Görsel İçerik Üretimi", "İsteğe Bağlı Farklı Ortamlarda Ürün Görsel Çekimi", "İsteğe Bağlı Canlı Manken İçerikli Görseller"],
    btn: "Started Now",
    single:"Fiyat Alınız"
  }
];


}

