<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" style="background: url('assets/images/shape02.png') top left;"
  id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Danışmanlık Detayları </h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/">Sellystic</a></li>
          <li class="breadcrumb-item"><a routerLink="/amazon">Amazon Danışmanlık</a></li>
          <li class="breadcrumb-item active" aria-current="page">Detay</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<section class="section" style="padding: 30px 0 !important">
  <div class="container mt-lg-3">   
    <div class="row align-items-center">
      <div class="col-12 mt-4 pt-2">
        <div class="text-center">
          <ul ngbNav #nav="ngbNav"
            class="nav nav-pills rounded-pill justify-content-center d-inline-block border py-1 px-2" id="pills-tab"
            role="tablist">
            @for(item of amazonConsulting;track $index){            
            <li ngbNavItem class="nav-item d-inline-block" [ngbNavItem]="$index">
              <a ngbNavLink class="nav-link px-3 rounded-pill" >
                {{item.itemText}}
              </a>
              <ng-template ngbNavContent>
                <app-consulting-detail [items]="item"></app-consulting-detail>
              </ng-template>
            </li>  
            }          
          </ul>
          <div [ngbNavOutlet]="nav" class="col-12"></div>
        </div>
      </div>
      <!--end col-->
    </div>
  </div>
  <!--end container-->
</section>

<section class="section" style="padding: 55px 0 !important">
  <div class="container">
      <div class="row">
          <div class="col-lg-2 col-md-4 col-6 mt-2 pt-2">
              <div class="card features feature-primary explore-feature shadow rounded text-center">
                  <div class="card-body">
                      <div class="icons rounded-circle shadow-lg d-inline-block">
                          <img src="assets/images/icon/paint-bucket.png" class="avatar avatar-md-sm" alt="">
                      </div>
                      <div class="content mt-3">
                          <h6 class="mb-0"><a href="javascript:void(0)" class="title text-dark">Liste</a></h6>
                      </div>
                  </div>
              </div>
          </div><!--end col-->

          <div class="col-lg-2 col-md-4 col-6 mt-2 pt-2">
              <div class="card features feature-primary explore-feature shadow rounded text-center">
                  <div class="card-body">
                      <div class="icons rounded-circle shadow-lg d-inline-block">
                          <img src="assets/images/icon/wave.png" class="avatar avatar-md-sm" alt="">
                      </div>
                      <div class="content mt-3">
                          <h6 class="mb-0"><a href="javascript:void(0)" class="title text-dark">Analiz</a></h6>
                      </div>
                  </div>
              </div>
          </div><!--end col-->

          <div class="col-lg-2 col-md-4 col-6 mt-2 pt-2">
              <div class="card features feature-primary explore-feature shadow rounded text-center">
                  <div class="card-body">
                      <div class="icons rounded-circle shadow-lg d-inline-block">
                          <img src="assets/images/icon/domain.png" class="avatar avatar-md-sm" alt="">
                      </div>
                      <div class="content mt-3">
                          <h6 class="mb-0"><a href="javascript:void(0)" class="title text-dark">Global</a></h6>
                      </div>
                  </div>
              </div>
          </div><!--end col-->

          <div class="col-lg-2 col-md-4 col-6 mt-2 pt-2">
              <div class="card features feature-primary explore-feature shadow rounded text-center">
                  <div class="card-body">
                      <div class="icons rounded-circle shadow-lg d-inline-block">
                          <img src="assets/images/icon/chatbot.png" class="avatar avatar-md-sm" alt="">
                      </div>
                      <div class="content mt-3">
                          <h6 class="mb-0"><a href="javascript:void(0)" class="title text-dark">İnovaktif</a></h6>
                      </div>
                  </div>
              </div>
          </div><!--end col-->

          <div class="col-lg-2 col-md-4 col-6 mt-2 pt-2">
              <div class="card features feature-primary explore-feature shadow rounded text-center">
                  <div class="card-body">
                      <div class="icons rounded-circle shadow-lg d-inline-block">
                          <img src="assets/images/icon/mail.png" class="avatar avatar-md-sm" alt="">
                      </div>
                      <div class="content mt-3">
                          <h6 class="mb-0"><a href="javascript:void(0)" class="title text-dark">İletişim</a></h6>
                      </div>
                  </div>
              </div>
          </div><!--end col-->

          <div class="col-lg-2 col-md-4 col-6 mt-2 pt-2">
              <div class="card features feature-primary explore-feature shadow rounded text-center">
                  <div class="card-body">
                      <div class="icons rounded-circle shadow-lg d-inline-block">
                          <img src="assets/images/icon/cubes.png" class="avatar avatar-md-sm" alt="">
                      </div>
                      <div class="content mt-3">
                          <h6 class="mb-0"><a href="javascript:void(0)" class="title text-dark">Optimizasyon</a></h6>
                      </div>
                  </div>
              </div>
          </div><!--end col-->
      </div><!--end row-->
  </div><!--end container-->

</section>

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->