import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SocialMediaLinkDto } from 'src/app/models/socialMediaLinkDto';
import { MainPageService } from 'src/app/services/main-page.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})


/***
 * Footer Component
 */
export class FooterComponent implements OnInit {

  @Input() footerVariant: string;
  @Input() hideFooter: boolean;

  newsForm!: UntypedFormGroup;
  submitted = false;

  //Get Year
  year = new Date().getFullYear()
  email:string="";

  constructor(private formBuilder: UntypedFormBuilder, private mainService:MainPageService) { }

  ngOnInit(): void {
    /**
     * Form Validatyion
     */
    this.newsForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.getSocialMediaLinks();
  }
  
  // convenience getter for easy access to form fields
  get form() { return this.newsForm.controls; }


  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newsForm.invalid) {
      return;
    }
  }


  socialList:SocialMediaLinkDto[]=[];
  getSocialMediaLinks(){
    this.mainService.getSocialMediaLink().then(data =>{
      this.socialList = data;
    }).catch(error => {
      
      Swal.fire({
        title: "Hata",
        text: error,
        icon: 'warning',
        confirmButtonText: 'Tamam',
        showCloseButton: true, // kapatma için çarpı butonu
        allowOutsideClick: false // dışarı tıklayınca kapanmasın
      });
    });
  }
  subscribe(){
 
    if(this.email==""){
      
      Swal.fire({
        title: "Eksik var",
        text: "Boş bilgi gönderilemez. Lütfen e-posta adresinizi yazınız.",
        icon: 'warning',
        confirmButtonText: 'Tamam',
        showCloseButton: true, // kapatma için çarpı butonu
        allowOutsideClick: false // dışarı tıklayınca kapanmasın
      });
      return;
    }
    this.mainService.addSubscribe(this.email).subscribe(data=> {
      
      Swal.fire({
        title: "Başarılı",
        text: data,
        icon: 'success',
        confirmButtonText: 'Tamam',
        showCloseButton: true, // kapatma için çarpı butonu
        allowOutsideClick: true // dışarı tıklayınca kapanmasın
      });
      this.email="";
    });
  }

}
