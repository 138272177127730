import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AmazonConsultingDto } from 'src/app/models/amazonConsultingDto';
import { AmazonConstultingHtmlPipe } from 'src/app/pipes/amazon-constulting-html.pipe';
import { MainPageService } from 'src/app/services/main-page.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-amazon-detail',
  standalone: false,
  templateUrl: './amazon-detail.component.html',
  styleUrl: './amazon-detail.component.css',
  providers:[AmazonConstultingHtmlPipe]
})
export class AmazonDetailComponent implements OnInit {
  @ViewChild('nav') nav: NgbNav;

  constructor(private modalService: NgbModal, private mainPageService:MainPageService, private pipe:AmazonConstultingHtmlPipe, private router:Router){}
  categoryID:number;
  ngOnInit(): void {
    this.mainPageService.selectedId.subscribe(id=>{
      if(!id){
        this.router.navigate(['/amazon']);
      }else{
        this.categoryID = id;
        this.getAmazonConsultingList(this.categoryID);
      }
    });
  }

  ngAfterViewInit() {
    this.nav.select(0); 
  }

  amazonConsulting:AmazonConsultingDto[]=[];
  getAmazonConsultingList(id:number){
    this.mainPageService.getAmazonConsulting(id).then(data =>{
      this.amazonConsulting = data;
      
    }).catch(error => {
      
      Swal.fire({
        title: 'Upps.',
        text: error,
        icon: 'success',
        confirmButtonText: 'Tamam',
        showCloseButton: true, // kapatma için çarpı butonu
        allowOutsideClick: false, // dışarı tıklayınca kapanmasın
        
      });
    });
  }

 // Set Topbar Option
 navClass = 'nav-dark';
 Menuoption = 'center';
 footerClass: true;
  footerVariant = 'bg-light';
 Settingicon = true;
 num: number = 0;

 monthlyData = [
  {
    title: "Free",
    price: 0,
    list: ["Full Access", "Source Files"],
    btn: "Buy Now"
  },
  {
    warning: "Best",
    title: "Starter",
    price: 39,
    list: ["Full Access", "Source Files", "Free Appointments"],
    btn: "Get Started"
  },
  {
    title: "Professional",
    price: 59,
    list: ["Full Access", "Source Files", "1 Domain Free", "Enhanced Security"],
    btn: "Try It Now"
  },
  {
    title: "Ultimate",
    price: 79,
    list: ["Full Access", "Source Files", "1 Domain Free", "Enhanced Security", "Free Installment"],
    btn: "Started Now"
  }
];

/**
 * Monthly Pricing Data
 */
yearlyData = [
  {
    title: "Free",
    price: 10,
    list: ["Full Access", "Source Files"],
    btn: "Buy Now"
  },
  {
    warning: "Best",
    title: "Starter",
    price: 139,
    list: ["Full Access", "Source Files", "Free Appointments"],
    btn: "Get Started"
  },
  {
    title: "Professional",
    price: 159,
    list: ["Full Access", "Source Files", "1 Domain Free", "Enhanced Security"],
    btn: "Try It Now"
  },
  {
    title: "Ultimate",
    price: 179,
    list: ["Full Access", "Source Files", "1 Domain Free", "Enhanced Security", "Free Installment"],
    btn: "Started Now"
  }
];



 option = {
   startVal: this.num,
   useEasing: true,
   duration: 2,
   decimalPlaces: 0,
 };
 customOptions: OwlOptions = {
   // loop: true,
   mouseDrag: true,
   touchDrag: false,
   pullDrag: false,
   // autoplay: true,
   navSpeed: 700,
   navText: ['', ''],
   responsive: {
     0: {
       items: 1
     },
     600: {
       items: 2
     },
     900: {
       items: 3
     }
   },
   nav: false,
 };
}
