
    <div class="container mt-5">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6 col-12">
                <img [src]="uri + items.imageUrl" class="img-fluid shadow rounded" alt="{{items.contentTitle}}" data-aos="fade-up" data-aos-duration="1400">
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-lg-4" id="plainDiv">
                    <h4 class="title mb-4">{{items.itemText}} : <span class="text-primary">{{items.contentTitle}}</span></h4>

                    
                    <div  [innerHTML]="items.contentPart.toString() | amazonConstultingHtml"></div>
                    </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--enc container-->
