<!-- Hero Start -->
<section class="bg-half-260 bg-primary d-table w-100" id="home"
  style="background: url('assets/images/software/bg.png') center center;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row align-items-center position-relative mt-5" style="z-index: 1;">
      <div class="col-lg-6 col-md-12">
        <div class="title-heading mt-4 text-lg-left">
          <h1 class="heading mb-3 title-dark text-white">E-İhracatla Küresel Başarıya Ulaşın</h1>
          <p class="para-desc text-white-50">Kapsamlı danışmanlık, yazılım, lojistik, gümrük ve muhasebe çözümlerimizle
            işinizi yeni zirvelere taşıyın.</p>
          <div class="mt-4">
            <button (click)="goAmazon()" class="btn btn-light"><i class="uil uil-align-center-h"></i> Çözümlerimizi
              Keşfedin</button>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-12 mt-4 pt-2">
        <div class="position-relative">
          <div class="software_hero">
            <img src="assets/images/software/software.png" class="img-fluid d-block"
              alt="Sellystic'in yenilikçi yazılım çözümleri" />
          </div>

        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Section Start -->
<section class="section pt-md-4 pt-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Amazon'da Başarının Anahtarını Keşfedin!
          </h4>
          <p class="text-muted para-desc mb-0 mx-auto" data-aos="fade-up" data-aos-duration="1400">
            <span class="text-primary fw-bold">Sellystic</span> ile e-ihracat yolculuğunuzda rekabet avantajı elde edin.
            Fırsatları kaçırmayın!
          </p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="mt-4 pt-2 text-center">
        <ul ngbNav #nav="ngbNav" class="nav-pills nav-justified flex-sm-row mb-0 col-lg-8 col-md-12 offset-md-2">
          <!-- Mağaza Kurulumu -->
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">Mağaza Kurulumu</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <img src="assets/images/saas/classic04.png" data-aos="fade-up" data-aos-duration="1400"
                    class="img-fluid mx-auto d-block shadow rounded" alt="Amazon Store Setup">
                </div>

                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="section-title ms-md-4 text-md-start">
                    <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Amazon'da Mağazanızı Kurun ve
                      Global Pazarlara Adım Atın!</h4>
                    <p class="para-desc text-muted" data-aos="fade-up" data-aos-duration="1400">
                      Amazon'da kendi hesabınızı açarak dünyanın dört bir yanındaki müşterilere ulaşın. Sellystic'in
                      modern ve yenilikçi çözümleriyle işinizi bir sonraki seviyeye taşıyın. Bugün katılın ve global
                      pazardaki yerinizi sağlamlaştırın!
                    </p>
                    <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                      <button (click)="goAmazon()" class="text-primary p-1 px-2 shadow rounded me-3">Daha Fazla Bilgi <i
                          class="uil uil-angle-right-b"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>

          <!-- Ödeme Alt Yapısı -->
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">Ödeme Alt Yapısı</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="section-title me-md-4 text-md-start">
                    <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Finansal Köprülerimizle
                      Gelirlerinizi Kolayca Yönetin!</h4>
                    <p class="para-desc text-muted" data-aos="fade-up" data-aos-duration="1400">
                      Uluslararası satışlarınızdan elde ettiğiniz gelirleri güvenle yönetin. Sellystic, finansal destek
                      hizmetleriyle ödeme süreçlerinizde kolaylık sağlar. E-ticaret başarınızı finansal çözümlerimizle
                      güçlendirin.
                    </p>
                    <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                      <button (click)="goAmazon()" class="text-primary p-1 px-2 shadow rounded me-3">Daha Fazla Bilgi <i
                        class="uil uil-angle-right-b"></i></button>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 order-1 order-md-2">
                  <img src="assets/images/saas/classic02.png" data-aos="fade-up" data-aos-duration="1400"
                    class="img-fluid mx-auto d-block shadow rounded" alt="Financial Infrastructure">
                </div>
              </div>
            </ng-template>
          </li>

          <!-- Lojistik -->
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center pt-1 pb-1">
                <h6 class="mb-0">Lojistik</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <img src="assets/images/saas/classic03.png" data-aos="fade-up" data-aos-duration="1400"
                    class="img-fluid mx-auto d-block shadow rounded" alt="Logistics Solutions">
                </div>

                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="section-title ms-md-4 text-md-start">
                    <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Lojistik Süreçlerinizi Sellystic
                      ile Kolaylaştırın!</h4>
                    <p class="para-desc text-muted" data-aos="fade-up" data-aos-duration="1400">
                      Amazon Avrupa'da lojistik süreçleriniz Sellystic ile sorunsuz ve verimli. Gümrükten depolamaya,
                      tüm hizmetlerimizle lojistik zorluklarını avantaja dönüştürün. Başarınız için yenilikçi çözümler
                      sunuyoruz.
                    </p>
                    <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                      <button (click)="goAmazon()" class="text-primary p-1 px-2 shadow rounded me-3">Daha Fazla Bilgi <i
                        class="uil uil-angle-right-b"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
        <div class="row">
          <div [ngbNavOutlet]="nav" class="col-12 mt-4 pt-2"></div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Hero Start -->
<section class=" d-table w-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-7">
        <div class="title-heading mt-1">
          <div class="alert alert-white alert-pills shadow" role="alert">
            <span class="content" style="font-style: italic;"> Her Ürün İçin Küresel Bir Pazar,
              Her Müşteri İçin Yerel Bir Yaklaşım...</span>
          </div>
          <h1 class="heading mb-3">
            <ngx-typed-js
              [strings]="['E-Ticaret','E-İhracat', 'Yazılım', 'Lojistik', 'Muhasebe', 'Raporlama', 'Devlet Destekleri']"
              [shuffle]="true" [typeSpeed]="80" [loop]="true">
              Sellystic'in Yenlikçi <br><span class="element text-primary typing"></span><br>Çözümleriyle Tanışın
            </ngx-typed-js>
          </h1>
          <p class="para-desc text-muted">
            Sellystic ile lojistikten e-ticarete, pazarlamadan finansa tüm iş süreçlerinizi optimize edin. Rekabette bir
            adım öne çıkın ve sürdürülebilir başarı elde edin.
          </p>
          <div class="mt-4">
            <button (click)="goSolution()" class="btn btn-outline-primary rounded"><i class="uil uil-sorting"></i>
              Çözümlerimizi Keşfedin</button>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="position-relative">
          <img src="assets/images/busi01.jpg" class="rounded img-fluid mx-auto d-block"
            alt="Sellystic Çözümleriyle İşinizi Büyütün" />
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<section class="section pt-md-4 pt-5">
  <div class="container">

    <div class="row justify-content-center">
      <div class="col-12 text-center mt-100">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Çözümlerimiz</h4>
          <p class="text-muted para-desc mx-auto mb-0"><span class="text-primary fw-bold">Sellystic</span> ile global
            pazara adım atın. İşletmenizi uluslararası başarıya taşıyacak çözümlerimizi keşfedin.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-services [servicesData]="servicesData"
      service_class="features feature-primary text-center mt-5"></app-services>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- End Features -->


  <!--end container-->
</section>


<section class="section bg-cta" style="background: url('assets/images/2.jpg')" id="cta">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center shadow-md">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title title-dark text-white mb-4">
            Küresel Pazarda Yer Almanın Zamanı Geldi
          </h4>
          <p class="text-white-50 para-dark para-desc mx-auto">
            Dünya çapında markanızı büyütme fırsatını yakalayın. Biz, Sellystic olarak, e-ihracat yolculuğunuzda
            yanınızdayız. İşinizi yaparken, uluslararası pazarlara açılmanızı kolaylaştıracak tüm adımları biz
            üstlenelim.
          </p>
          <button (click)="goContactUs()" class="btn btn-primary mt-4">Hemen Başlayın</button>
        </div>
      </div>
    </div>
  </div>
</section>




<section class="section pt-md-4 pt-5 mb-0" style="padding: 0px 0 !important;">
  <div class="container mt-100 mt-60 mb-0">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <img src="assets/images/software/mobile01.png" class="img-fluid" alt="E-İhracat Danışmanlığı">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Küresel Başarıya <span class="text-primary fw-bold">Sellystic</span> ile Ulaşın</h4>
          <p class="text-muted">Günümüz iş dünyasında, zamanınızın kıymetini biliyor ve onu en verimli şekilde
            kullanmanızı sağlayacak çözümler sunuyoruz. Sellystic, e-ihracat süreçlerinizde zorlukları aşmanız için
            güçlü bir destek sağlar. Profesyonel ekibimizle, işletmenizi küresel pazarda öne çıkarmak için yenilikçi
            stratejiler ve son teknolojileri kullanıyoruz.</p>
          <ul class="list-unstyled text-muted">
            <li><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Detaylı Pazar
              Analiziyle Doğru Kararlar Alın</li>
            <li><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Entegre İş
              Süreçlerinizle Verimliliği Maksimize Edin</li>
            <li><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Birebir
              Danışmanlıkla Her Adımda Yanınızdayız</li>
            <li><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>İnovasyonla
              Rekabette Öne Geçin</li>
          </ul>
          <p class="text-muted mt-3">Sellystic'in sunduğu imkanlarla, işletmenizin küresel pazarda güçlü bir konuma
            ulaşmasını sağlayın. Bugün bize katılın, dinamik ve heyecan verici bu yolculukta yerinizi alın. Fırsatları
            kaçırmayın, geleceğinizi şekillendirmeye şimdi başlayın!</p>
          <div class="mt-4">
            <button (click)="goContactUs()" class="btn btn-primary">Şimdi Başvurun</button>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<!--end section-->
<!-- section End -->

<section class="section mt-0">
  <div class="container mt-0">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title mb-4 pb-2 text-center">
          <h4 class="title mb-4">Sıkça Sorulan Sorular</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Sellystic ile iş sürecinizin her aşamasında yanınızdayız, sadece
            belirli sorularınıza değil, her türlü ihtiyacınıza çözüm buluyoruz.</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">Sellystic nedir?</h5>
            <p class="answer text-muted mb-0">Küresel pazarlara erişim sürecinizi kolaylaştıran ve tüm e-ihracat
              işlemlerinizi tek bir panel üzerinden yönetmenize olanak tanıyan kapsamlı bir çözüm platformudur.</p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">Sellystic kimler için idealdir?</h5>
            <p class="answer text-muted mb-0">Yurtdışı pazarlara açılmak isteyen üreticilerden, e-ticaret
              platformlarında yer almak isteyen işletmelere kadar her ölçekteki girişim için idealdir.</p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">Sellystic kullanarak hangi zorlukların üstesinden gelebilirim?</h5>
            <p class="answer text-muted mb-0">Pazar analizi, ürün hedefleme, lojistik ve gümrük işlemleri gibi
              e-ihracatın getirdiği zorlukları, entegre çözümlerimiz ve uzman danışmanlık hizmetlerimizle aşmanızı
              sağlıyoruz.</p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">Sellystic'in sunduğu temel çözümler nelerdir?</h5>
            <p class="answer text-muted mb-0">Detaylı pazar analizi, entegre iş süreçleri yönetimi, kişiselleştirilmiş
              danışmanlık, ve e-ihracat operasyonlarınız için tam destek ve eğitim programları sunuyoruz.</p>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->

    <div class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Daha Fazla Bilgi Almak İster misiniz?</h4>
          <p class="text-muted para-desc mx-auto">Ekibimiz, Sellystic'in işletmenize nasıl değer katabileceğini
            keşfetmek için sorularınızı yanıtlamaya hazır. Hemen iletişime geçin ve işinizi globale 
            taşıyın.</p>
          <button (click)="goContactUs()" class="btn btn-primary mt-4"><i class="uil uil-phone"></i> İletişime Geçin</button>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>



<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->